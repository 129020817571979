import React, { useRef } from 'react';
import { motion } from "framer-motion";
import { useSelector } from 'react-redux';
import { getSortedProjects } from '../../store/data';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faFile } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import defaultProjectImage from '../../img/default-project.png';

const MobileProjectsSection = ({ currentProject, handleNextProject, handlePrevProject, language, informations }) => {
  const projects = useSelector(getSortedProjects);
  const sectionRef = useRef(null);
  const currentProjectData = projects[currentProject];

  // Debug için projeleri kontrol et
  React.useEffect(() => {
    // console.log("All projects:", projects);
    // console.log("Current project:", currentProjectData);
  }, [projects, currentProjectData]);

  return (
    <div ref={sectionRef} className="min-h-screen bg-stone-900 px-4 py-16 relative">
      <motion.h2 
        className="text-3xl font-font text-white text-center mb-8"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        {informations[language].projects.title}
      </motion.h2>

      <motion.p 
        className="text-stone-400 text-center mb-12 max-w-md mx-auto"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: 0.2 }}
      >
        {informations[language].projects.description}
      </motion.p>

      {currentProjectData && (
        <div className="relative">
          {/* Navigation Buttons */}
          <div className="absolute top-1/2 -translate-y-1/2 left-0 right-0 flex justify-between px-2 z-10">
            <button 
              onClick={handlePrevProject}
              className="bg-stone-800/80 p-3 rounded-full text-white hover:bg-stone-700 transition-colors"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button 
              onClick={handleNextProject}
              className="bg-stone-800/80 p-3 rounded-full text-white hover:bg-stone-700 transition-colors"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>

          {/* Project Card */}
          <motion.div
            key={currentProject}
            className="bg-stone-800 rounded-lg overflow-hidden"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4 }}
          >
            {/* Project Image */}
            {currentProjectData.img_Url && currentProjectData.img_Url[0] !== "none.png" && (
              <div className="relative pb-[56.25%] bg-stone-700">
                <img 
                  src={currentProjectData.img_Url[0]}
                  alt={currentProjectData.title}
                  className="absolute inset-0 w-full h-full object-cover"
                  onError={(e) => {
                    console.error("Image load error for URL:", currentProjectData.img_Url[0]);
                    e.target.onerror = null;
                    e.target.src = defaultProjectImage;
                  }}
                />
                {/* Debug için URL göster */}
                {/*<div className="absolute bottom-0 left-0 right-0 bg-black/50 text-white text-xs p-1">
                  Current URL: {currentProjectData.img_Url[0]}
                </div> */}
              </div>
            )}

            {/* Project Content */}
            <div className="p-6">
              <h3 className="text-xl font-font text-white mb-4">
                {currentProjectData.title?.[language] || currentProjectData.title}
              </h3>
              <p className="text-stone-300 mb-6">
                {currentProjectData.text?.[language] || currentProjectData.text}
              </p>

              {/* Technologies */}
              <div className="flex flex-wrap gap-2 mb-6">
                {currentProjectData.top_Link.map((tech, index) => (
                  <motion.span
                    key={index}
                    className="bg-stone-700 text-white px-3 py-1 rounded-full text-sm"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    {tech.link}
                  </motion.span>
                ))}
              </div>

              {/* Links */}
              <div className="flex gap-4">
                {currentProjectData.bottom_Link.map((link, index) => (
                  <a
                    key={index}
                    href={link.link}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-stone-700 text-white p-3 rounded-full hover:bg-stone-600 transition-colors"
                  >
                    <FontAwesomeIcon icon={link.img_name === 'faGithub' ? faGithub : faFile} />
                  </a>
                ))}
              </div>
            </div>
          </motion.div>

          {/* Project Indicators */}
          <div className="flex justify-center gap-2 mt-6">
            {Object.keys(projects).map((_, index) => (
              <div
                key={index}
                className={`w-2 h-2 rounded-full transition-colors ${
                  index === currentProject ? 'bg-white' : 'bg-stone-700'
                }`}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileProjectsSection;

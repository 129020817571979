import React from 'react';
import { motion } from 'framer-motion';

const LoadingScreen = () => {
  const particles = Array.from({ length: 50 });

  return (
    <div className="bg-gradient-to-br from-stone-900 to-stone-800 w-full min-h-screen flex items-center justify-center">
      <motion.div
        className="relative w-full max-w-2xl px-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Particle Background */}
        <div className="absolute inset-0 overflow-hidden">
          {particles.map((_, i) => (
            <motion.div
              key={i}
              className="absolute w-1 h-1 bg-white/10 rounded-full"
              initial={{
                x: Math.random() * window.innerWidth,
                y: Math.random() * window.innerHeight,
                scale: Math.random() * 0.5 + 0.5
              }}
              animate={{
                x: [null, Math.random() * window.innerWidth],
                y: [null, Math.random() * window.innerHeight],
                scale: [null, Math.random() * 0.5 + 0.5]
              }}
              transition={{
                duration: Math.random() * 2 + 1,
                repeat: Infinity,
                repeatType: 'mirror',
                ease: 'easeInOut'
              }}
            />
          ))}
        </div>

        {/* Loading Content */}
        <div className="relative z-10">
          <motion.div
            className="text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            <motion.h1
              className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent mb-4"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              Yükleniyor...
            </motion.h1>

            <motion.div
              className="w-full h-2 bg-stone-700 rounded-full overflow-hidden mt-8"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ duration: 1.5, ease: "easeInOut" }}
            >
              <motion.div
                className="h-full bg-gradient-to-r from-purple-400 to-pink-600"
                initial={{ width: '0%' }}
                animate={{ width: '100%' }}
                transition={{ 
                  duration: 2, 
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatType: 'mirror'
                }}
              />
            </motion.div>

            <motion.p
              className="mt-4 text-stone-400 text-sm"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1, duration: 0.5 }}
            >
              Veriler yükleniyor, lütfen bekleyin...
            </motion.p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default LoadingScreen;

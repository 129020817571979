import React, { useEffect, useRef } from 'react';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptopCode, faServer, faCode, faGraduationCap, faCertificate } from "@fortawesome/free-solid-svg-icons";

const MobileExperienceSection = ({ experiences, language, informations, certificates }) => {
  const sectionRef = useRef(null);

  const getIcon = (iconName) => {
    switch (iconName) {
      case 'laptop-code':
        return faLaptopCode;
      case 'server':
        return faServer;
      case 'code':
        return faCode;
      default:
        return faCode;
    }
  };

  return (
    <div ref={sectionRef} className="min-h-screen bg-stone-900 px-4 py-16 relative">
      <motion.h2 
        className="text-3xl font-font text-white text-center mb-12"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        {informations[language].experience.title}
      </motion.h2>

      <div className="space-y-8 max-w-md mx-auto">
        {experiences && Object.entries(experiences).map(([key, exp], index) => (
          <motion.div
            key={key}
            className="bg-stone-800 rounded-xl p-6 shadow-lg border border-stone-700"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.2 }}
          >
            <div className="flex items-center mb-4">
              <div className="bg-stone-700 p-4 rounded-xl border border-stone-600 mr-4">
                <FontAwesomeIcon icon={getIcon(exp.icon)} className="text-white text-2xl" />
              </div>
              <div>
                <h3 className="text-xl font-font text-white mb-1">{exp.title[language]}</h3>
                <h4 className="text-stone-400 text-sm">{exp.company[language]}</h4>
              </div>
            </div>

            <p className="text-stone-300 mb-4 text-sm leading-relaxed">{exp.description[language]}</p>
            
            <div className="flex flex-wrap gap-2 mb-4">
              {exp.skills.map((skill, i) => (
                <motion.span
                  key={i}
                  className="bg-stone-700 text-white px-3 py-1 rounded-lg text-sm border border-stone-600"
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 + i * 0.1 }}
                >
                  {skill}
                </motion.span>
              ))}
            </div>

            <div className="text-stone-400 text-sm font-medium">
              {exp.date}
            </div>
          </motion.div>
        ))}

        {/* University Section */}
        {informations?.[language]?.experience?.university && (
          <motion.div
            className="bg-stone-800 rounded-xl p-6 shadow-lg border border-stone-700"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: experiences ? Object.keys(experiences).length * 0.2 : 0 }}
          >
            <div className="flex items-center mb-4">
              <div className="bg-stone-700 p-4 rounded-xl border border-stone-600 mr-4">
                <FontAwesomeIcon icon={faGraduationCap} className="text-white text-2xl" />
              </div>
              <div>
                <h3 className="text-xl font-font text-white mb-1">
                  {informations[language].experience.university.name}
                </h3>
                <h4 className="text-stone-400 text-sm">
                  {informations[language].experience.university.department}
                </h4>
              </div>
            </div>

            <div className="text-stone-400 text-sm font-medium">
              {informations[language].experience.university.date}
            </div>
          </motion.div>
        )}

        {/* Certificates Section */}
        {certificates && certificates.length > 0 && (
          <motion.div
            className="mt-12"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-2xl font-font text-white text-center mb-6">
              {language === 'tr' ? 'Sertifikalar' : 'Certificates'}
            </h2>

            <div className="grid gap-4">
              {certificates.map((cert, index) => (
                <motion.div
                  key={index}
                  className="bg-stone-800 rounded-xl p-6 shadow-lg border border-stone-700"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className="flex items-center gap-4">
                    <div className="bg-stone-700 p-3 rounded-xl border border-stone-600">
                      <FontAwesomeIcon icon={faCertificate} className="text-white text-xl" />
                    </div>
                    <div>
                      <h3 className="text-lg font-font text-white mb-1">{cert.name}</h3>
                      <p className="text-stone-400 text-sm">{cert.issuer}</p>
                      <p className="text-stone-500 text-sm mt-1">{cert.date}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default MobileExperienceSection;

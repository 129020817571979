import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const sectionNames = {
  hero: { tr: "Ana Sayfa", en: "Home" },
  experience: { tr: "Deneyim", en: "Experience" },
  projects: { tr: "Projeler", en: "Projects" }
};

const NavigationDots = ({ activeSection, sections, onDotClick, language }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      if (currentScrollY > lastScrollY) {
        setIsVisible(true);
      } else {
        if (currentScrollY < 100) {
          setIsVisible(false);
        }
      }
      
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const getProgress = (index) => {
    const currentIndex = sections.indexOf(activeSection);
    if (index < currentIndex) return 100;
    
    // Eğer aktif section ise ve scroll pozisyonuna göre progress hesapla
    if (index === currentIndex) {
      const sectionElement = document.getElementById(activeSection);
      if (sectionElement) {
        const rect = sectionElement.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const progress = (viewportHeight - rect.top) / (viewportHeight / 2);
        return Math.min(Math.max(progress * 100, 0), 100);
      }
    }
    return 0;
  };

  return (
    <AnimatePresence>
      <motion.div 
        className="fixed top-0 left-0 right-0 z-10 flex justify-center pt-8"
        initial={{ opacity: 0, y: -20 }}
        animate={{ 
          opacity: isVisible ? 1 : 0,
          y: isVisible ? 0 : -20
        }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex items-center">
          {sections.map((section, index) => (
            <React.Fragment key={section}>
              <button
                onClick={() => onDotClick(section)}
                className="group relative"
              >
                <div className="relative">
                  {/* Background Pill */}
                  <div className="w-32 h-12 rounded-full bg-stone-800 border-2 border-white/20 overflow-hidden">
                    {/* Progress Fill */}
                    <motion.div
                      className="absolute inset-0 rounded-full bg-gradient-to-r from-white/20 to-white/30"
                      initial={{ scaleX: 0 }}
                      animate={{ scaleX: getProgress(index) / 100 }}
                      transition={{ duration: 0.4, ease: "easeInOut" }}
                      style={{ transformOrigin: 'left' }}
                    />
                    
                    {/* Text */}
                    <div className="absolute inset-0 flex items-center justify-center">
                      <span className={`text-sm font-medium transition-colors duration-300 ${
                        getProgress(index) > 50 ? 'text-white' : 'text-white/70'
                      }`}>
                        {sectionNames[section]?.[language]}
                      </span>
                    </div>
                  </div>

                  {/* Active Indicator */}
                  {activeSection === section && (
                    <motion.div
                      className="absolute -inset-1 rounded-full border-2 border-white"
                      layoutId="activeSection"
                      transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                    />
                  )}
                </div>
              </button>

              {/* Connecting Line */}
              {index < sections.length - 1 && (
                <div className="h-[2px] w-4 bg-stone-800 relative mx-[-1px]">
                  <motion.div
                    className="absolute inset-0 rounded-full bg-gradient-to-r from-white/20 to-white/30"
                    initial={{ scaleX: 0 }}
                    animate={{ scaleX: getProgress(index) / 100 }}
                    transition={{ duration: 0.4, ease: "easeInOut" }}
                    style={{ transformOrigin: 'left' }}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default NavigationDots;

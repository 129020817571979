import React, { useState, useEffect } from 'react'
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Home from './Home';
import { fetchAllData } from "../service/service";
import { AnimatePresence } from "framer-motion";

const AnimatedRoutes = () => {
    const location = useLocation();
    const [open, setOpen] = useState(0);

    useEffect(() => {
        fetchAllData();
    }, []);
    
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Home open={open}/>}/>
                <Route path='/projects' element={<Navigate to="/" replace />} />
                <Route path='*' element={<Navigate to="/" replace />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes;

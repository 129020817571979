// Firebase App (the core Firebase SDK) is always required
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDdOxzvDJi2u_BNjbPwXZOI91a7W5D2QyE",
  authDomain: "halilozler-web-sitesi.firebaseapp.com",
  projectId: "halilozler-web-sitesi",
  storageBucket: "halilozler-web-sitesi.firebasestorage.app",
  messagingSenderId: "680053971330",
  appId: "1:680053971330:web:f9a626ae1c9a5a5221d79b",
  measurementId: "G-CCW73H6BF7",
  databaseURL: "https://halilozler-web-sitesi-default-rtdb.europe-west1.firebasedatabase.app/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

export { database };

import React, { useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptopCode, faServer, faCode, faGraduationCap, faCertificate } from "@fortawesome/free-solid-svg-icons";

const ExperienceSection = ({ experiences, language, informations, certificates }) => {
  const sectionRef = useRef(null);
  const certificatesRef = useRef(null);
  const [certificatesHeight, setCertificatesHeight] = useState(0);

  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" }
    }
  };

  useEffect(() => {
    const section = sectionRef.current;
    const certificatesSection = certificatesRef.current;
    
    if (!section || !certificatesSection) return;
    
    // Get certificates section height
    const handleResize = () => {
      setCertificatesHeight(certificatesSection.offsetHeight);
    };
    
    handleResize();
    window.addEventListener('resize', handleResize);
    let startY;
    let isScrolling = false;

    const handleWheel = (e) => {
      if (isScrolling) return;
      
      const rect = section.getBoundingClientRect();
      const isInView = rect.top <= 0 && rect.bottom >= window.innerHeight;
      
      if (isInView) {
        e.preventDefault();
        isScrolling = true;
        
        const direction = e.deltaY > 0 ? 1 : -1;
        const targetSection = direction > 0 ? section.nextElementSibling : section.previousElementSibling;
        
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
        
        setTimeout(() => {
          isScrolling = false;
        }, 1000);
      }
    };

    const handleTouchStart = (e) => {
      startY = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
      if (!startY || isScrolling) return;
      
      const rect = section.getBoundingClientRect();
      const isInView = rect.top <= 0 && rect.bottom >= window.innerHeight;
      
      if (isInView) {
        const currentY = e.touches[0].clientY;
        const direction = startY > currentY ? 1 : -1;
        
        if (Math.abs(startY - currentY) > 50) {
          e.preventDefault();
          isScrolling = true;
          
          const targetSection = direction > 0 ? section.nextElementSibling : section.previousElementSibling;
          
          if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth' });
          }
          
          setTimeout(() => {
            isScrolling = false;
            startY = null;
          }, 1000);
        }
      }
    };

    section.addEventListener('wheel', handleWheel, { passive: false });
    section.addEventListener('touchstart', handleTouchStart);
    section.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      window.removeEventListener('resize', handleResize);
      if (section) {
        section.removeEventListener('wheel', handleWheel);
        section.removeEventListener('touchstart', handleTouchStart);
        section.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);

  const getIcon = (iconName) => {
    switch (iconName) {
      case 'laptop-code':
        return faLaptopCode;
      case 'server':
        return faServer;
      case 'code':
        return faCode;
      default:
        return faCode;
    }
  };

  return (
    <div ref={sectionRef} className="min-h-screen bg-stone-800 relative px-4 py-20">
      <div className="max-w-6xl mx-auto">
        <motion.h2 
          className="text-4xl font-font text-white text-center mb-24"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeInUpVariants}
        >
          {informations?.[language]?.experience?.title}
        </motion.h2>

        <div className="relative">
          {/* Timeline Line */}
          <div className="absolute left-1/2 transform -translate-x-1/2 w-1 bg-white"
            style={{
              height: `calc(100% - ${certificatesHeight}px - 200px)`,
              top: 0
            }}
          />

          {/* Experience Items */}
          {experiences && Object.entries(experiences).map(([key, exp], index) => (
            <motion.div
              key={key}
              className={`flex items-center mb-32 ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={{
                hidden: { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
                visible: {
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                    delay: index * 0.2,
                    ease: "easeOut"
                  }
                }
              }}
            >
              {/* Content */}
              <div className={`w-5/12 ${index % 2 === 0 ? 'text-right pr-8' : 'text-left pl-8'}`}>
                <h3 className="text-2xl font-font text-white mb-2">{exp.title[language]}</h3>
                <h4 className="text-xl text-stone-400 mb-2">{exp.company[language]}</h4>
                <p className="text-stone-300 mb-4">{exp.description[language]}</p>
                <div className={`flex flex-wrap gap-2 ${index % 2 === 0 ? 'justify-end' : 'justify-start'}`}>
                  {exp.skills.map((skill, i) => (
                    <motion.span
                      key={i}
                      className="bg-stone-700 text-white px-3 py-1 rounded-full text-sm"
                      initial={{ opacity: 0, scale: 0.8 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.2 + i * 0.1 }}
                    >
                      {skill}
                    </motion.span>
                  ))}
                </div>
              </div>

              {/* Icon */}
              <motion.div 
                className="w-2/12 flex justify-center z-10"
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2, duration: 0.5 }}
              >
                <div className="bg-stone-800 p-4 rounded-full border-4 border-white">
                  <FontAwesomeIcon icon={getIcon(exp.icon)} className="text-white text-2xl" />
                </div>
              </motion.div>

              {/* Date */}
              <div className={`w-5/12 ${index % 2 === 0 ? 'text-left pl-8' : 'text-right pr-8'}`}>
                <motion.span 
                  className="text-stone-400 text-lg"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 + 0.3 }}
                >
                  {exp.date}
                </motion.span>
              </div>
            </motion.div>
          ))}

          {/* University Section */}
          {informations?.[language]?.experience?.university && (
            <motion.div
              className="flex flex-col items-center justify-center relative z-10 pb-16 border-b-2 border-stone-700"
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <div className="bg-stone-800 p-6 rounded-full border-4 border-white mb-8">
                <FontAwesomeIcon icon={faGraduationCap} className="text-white text-4xl" />
              </div>

              <motion.div
                className="text-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={fadeInUpVariants}
              >
                <h3 className="text-2xl font-font text-white">
                  {informations[language].experience.university.name}
                </h3>
                <p className="text-stone-400">
                  {informations[language].experience.university.department}, {informations[language].experience.university.date}
                </p>
              </motion.div>
            </motion.div>
          )}

          {/* Certificates Section */}
          {certificates && certificates.length > 0 && (
            <motion.div
              ref={certificatesRef}
              className="pt-24"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={fadeInUpVariants}
            >
              <div className="text-center mb-16">
                <h3 className="text-4xl font-font text-white mb-4">
                  {language === 'tr' ? 'Sertifikalar' : 'Certificates'}
                </h3>
                <div className="w-24 h-1 bg-white mx-auto rounded-full"></div>
              </div>

              <div className="grid grid-cols-2 gap-8 max-w-4xl mx-auto">
                {certificates.map((cert, index) => (
                  <motion.div
                    key={index}
                    className="bg-stone-800 rounded-xl p-6 shadow-lg border border-stone-700 hover:border-white transition-colors duration-300"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <div className="flex items-center gap-6">
                      <div className="bg-stone-700 p-4 rounded-xl border border-stone-600">
                        <FontAwesomeIcon icon={faCertificate} className="text-white text-2xl" />
                      </div>
                      <div>
                        <h4 className="text-xl font-font text-white mb-2">{cert.name}</h4>
                        <p className="text-stone-400 text-lg mb-1">{cert.issuer}</p>
                        <p className="text-stone-500">{cert.date}</p>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExperienceSection;

import { configureStore } from '@reduxjs/toolkit';
import siteReducer from './site';
import dataReducer from './data';

export const store = configureStore({
  reducer: {
    site: siteReducer,
    data: dataReducer
  },
});

export default store;

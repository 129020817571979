import React from 'react';
import { motion } from "framer-motion";
import { useSelector } from 'react-redux';
import { getSortedProjects } from '../../store/data';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faLanguage } from "@fortawesome/free-solid-svg-icons";
import Proje from '../Proje';

const ProjectsSection = ({ currentProject, handleNextProject, handlePrevProject, language, setLanguage, informations }) => {
  const projects = useSelector(getSortedProjects);
  const scrollToExperience = () => {
    const experienceSection = document.getElementById('experience');
    if (experienceSection) {
      experienceSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div id="projects-section" className="min-h-screen relative px-4">
      <div className="text-center pt-20 pb-10">
        <h2 className="text-4xl font-font text-white mb-4">{informations[language].projects.title}</h2>
        <p className="text-gray-400 max-w-2xl mx-auto">
          {informations[language].projects.description}
        </p>
      </div>

      <div className="flex justify-center items-center min-h-[70vh] relative">
        {projects && projects.length > 0 && (
          <Proje 
            id={currentProject.toString()} 
            item={projects[currentProject]} 
            last={projects.length - 1} 
            phone={window.innerWidth < 768}
            onNext={handleNextProject}
            onPrev={handlePrevProject}
            projects={projects}
            language={language}
          />
        )}
      </div>

      {/* Scroll to Experience Button */}
      <motion.div 
        className="absolute top-8 left-1/2 transform -translate-x-1/2 cursor-pointer"
        onClick={scrollToExperience}
        initial={{ y: 0 }}
        animate={{ y: [0, -10, 0] }}
        transition={{ repeat: Infinity, duration: 1.5 }}
      >
        <FontAwesomeIcon 
          icon={faArrowUp} 
          className="text-white text-3xl animate-bounce"
        />
      </motion.div>
    </div>
  );
};

export default ProjectsSection;

import { get, ref } from "firebase/database";
import { database } from "../firebase";
import store from "../store/index";
import { 
  setProjects,
  setExperiences,
  setCertificates,
  setLoading,
  setError 
} from "../store/data";

export const fetchAllData = async () => {
  try {
    store.dispatch(setLoading(true));
    
    // Fetch projects
    const projectsRef = ref(database, '/projects');
    const projectsSnapshot = await get(projectsRef);
    if (projectsSnapshot.exists()) {
      const projects = Object.entries(projectsSnapshot.val()).map(([id, project]) => ({
        ...project,
        id: Number(id)
      }));
      store.dispatch(setProjects(projects));
    }

    // Fetch experiences
    const experiencesRef = ref(database, '/experiences');
    const experiencesSnapshot = await get(experiencesRef);
    if (experiencesSnapshot.exists()) {
      store.dispatch(setExperiences(experiencesSnapshot.val()));
    }

    // Fetch certificates
    const certificatesRef = ref(database, '/certificates');
    const certificatesSnapshot = await get(certificatesRef);
    if (certificatesSnapshot.exists()) {
      store.dispatch(setCertificates(certificatesSnapshot.val()));
    }

  } catch (error) {
    // console.error("Firebase data fetch error:", error);
    store.dispatch(setError(error.message));
    throw error;
  } finally {
    store.dispatch(setLoading(false));
  }
};

import React, { useEffect, useState, useRef, useMemo } from 'react'
import { motion } from "framer-motion"
import { useSelector } from 'react-redux';
import { fetchAllData } from "../service/service";
import HeroSection from './Home/HeroSection';
import ProjectsSection from './Home/ProjectsSection';
import ExperienceSection from './Home/ExperienceSection';
import NavigationDots from './NavigationDots';
import LoadingScreen from './LoadingScreen';
import '../styles/mario-sprite.css';
import { selectExperiences, selectInformations } from '../store/data';
import MobileExperienceSection from './Mobile/MobileExperienceSection';
import MobileProjectsSection from './Mobile/MobileProjectsSection';
import MobileHome from './Mobile/MobileHome';

const SECTIONS = ['hero', 'experience', 'projects'];


// Mobil kontrolü için custom hook
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

const Home = ({open}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showName, setShowName] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [currentProject, setCurrentProject] = useState(0);
  const [language, setLanguage] = useState(() => {
    // Tarayıcı dilini al
    const browserLang = navigator.language || navigator.userLanguage;
    // Eğer Türkçe ise 'tr', değilse 'en' olarak ayarla
    return browserLang.toLowerCase().startsWith('tr') ? 'tr' : 'en';
  });
  const [activeSection, setActiveSection] = useState('hero');
  const lastScrollPosition = useRef(0);
  const isMobile = useIsMobile();

  const heroRef = useRef(null);
  const experienceRef = useRef(null);
  const projectsRef = useRef(null);
  
  const sectionRefs = useMemo(() => ({
    hero: heroRef,
    experience: experienceRef,
    projects: projectsRef
  }), []);

  const projects = useSelector((state) => state.data.projects);
  const experiences = useSelector(selectExperiences);
  const informations = useSelector(selectInformations);
  const certificates = useSelector((state) => state.data.certificates);

  useEffect(() => {
    const loadData = async () => {
      try {
        await fetchAllData();
        setIsLoading(false);
        setTimeout(() => {
          setShowName(true);
          setTimeout(() => setShowButtons(true), 500);
        }, 500);
      } catch (error) {
        // console.error("Projeler yüklenirken hata oluştu:", error);
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    let isScrolling = false;

    const handleScroll = () => {
      if (isScrolling) return;

      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const currentSection = SECTIONS.find(section => {
        const element = sectionRefs[section].current;
        if (element) {
          const { offsetTop, offsetHeight } = element;
          return scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight;
        }
        return false;
      });

      if (!currentSection) return;

      const currentIndex = SECTIONS.indexOf(currentSection);

      // Aktif section'ı güncelle
      setActiveSection(currentSection);

      // Scroll yönüne göre section değiştir
      if (scrollPosition > lastScrollPosition.current) {
        // Aşağı scroll
        const nextSection = SECTIONS[currentIndex + 1];
        if (nextSection) {
          const nextElement = sectionRefs[nextSection].current;
          const nextRect = nextElement.getBoundingClientRect();
          const nextVisibleHeight = Math.min(nextRect.bottom, window.innerHeight) - Math.max(nextRect.top, 0);
          const nextVisiblePercentage = (nextVisibleHeight / window.innerHeight) * 100;

          if (nextVisiblePercentage >= 25) {
            isScrolling = true;
            sectionRefs[nextSection].current?.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(nextSection);
            setTimeout(() => isScrolling = false, 800);
          }
        }
      } else if (scrollPosition < lastScrollPosition.current) {
        // Yukarı scroll
        const prevSection = SECTIONS[currentIndex - 1];
        if (prevSection) {
          const prevElement = sectionRefs[prevSection].current;
          const prevRect = prevElement.getBoundingClientRect();
          const prevVisibleHeight = Math.min(prevRect.bottom, window.innerHeight) - Math.max(prevRect.top, 0);
          const prevVisiblePercentage = (prevVisibleHeight / window.innerHeight) * 100;

          if (prevVisiblePercentage >= 10) {
            isScrolling = true;
            sectionRefs[prevSection].current?.scrollIntoView({ behavior: 'smooth' });
            setActiveSection(prevSection);
            setTimeout(() => isScrolling = false, 800);
          }
        }
      }

      lastScrollPosition.current = scrollPosition;
    };

    const throttledScroll = () => {
      if (!isScrolling) {
        requestAnimationFrame(handleScroll);
      }
    };

    window.addEventListener('scroll', throttledScroll, { passive: true });
    
    // İlk yüklemede aktif section'ı belirle
    const initialScrollPosition = window.scrollY + window.innerHeight / 2;
    const initialSection = SECTIONS.find(section => {
      const element = sectionRefs[section].current;
      if (element) {
        const { offsetTop, offsetHeight } = element;
        return initialScrollPosition >= offsetTop && initialScrollPosition < offsetTop + offsetHeight;
      }
      return false;
    });
    if (initialSection) setActiveSection(initialSection);

    return () => window.removeEventListener('scroll', throttledScroll);
  }, [sectionRefs]);

  const handleDotClick = (section) => {
    const element = sectionRefs[section].current;
    if (element) {
      setActiveSection(section);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNextProject = () => {
    setCurrentProject((prev) => (prev + 1) % projects.length);
  };

  const handlePrevProject = () => {
    setCurrentProject((prev) => (prev - 1 + projects.length) % projects.length);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-stone-900 w-full min-h-screen overflow-x-hidden">
      {!isMobile && (
        <NavigationDots 
          activeSection={activeSection}
          sections={SECTIONS}
          onDotClick={handleDotClick}
          language={language}
        />
      )}
      
      <div ref={sectionRefs.hero} id="hero">
        {isMobile ? (
          <MobileHome 
            showName={showName}
            showButtons={showButtons}
            language={language}
            setLanguage={setLanguage}
            informations={informations}
          />
        ) : (
          <HeroSection 
            showName={showName}
            showButtons={showButtons}
            language={language}
            setLanguage={setLanguage}
            informations={informations}
          />
        )}
      </div>

      <div ref={sectionRefs.experience} id="experience">
        {isMobile ? (
          <MobileExperienceSection 
            experiences={experiences} 
            language={language}
            informations={informations}
            certificates={certificates}
          />
        ) : (
          <ExperienceSection 
            experiences={experiences} 
            language={language}
            informations={informations}
            certificates={certificates}
          />
        )}
      </div>

      <div ref={sectionRefs.projects} id="projects">
        {isMobile ? (
          <MobileProjectsSection 
            currentProject={currentProject}
            handleNextProject={handleNextProject}
            handlePrevProject={handlePrevProject}
            language={language}
            informations={informations}
          />
        ) : (
          <ProjectsSection 
            projects={projects}
            currentProject={currentProject}
            handleNextProject={handleNextProject}
            handlePrevProject={handlePrevProject}
            language={language}
            informations={informations}
          />
        )}
      </div>
    </div>
  );
};

export default Home;

import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import ProjeItem from './ProjeItem';

const variants = {
    enter: (direction) => ({
        x: direction > 0 ? '-15%' : '15%',
        y: '5%',
        rotateY: direction > 0 ? -6 : 6,
        rotateZ: direction > 0 ? -6 : 6,
        scale: 0.95,
        opacity: 0.5,
        zIndex: 30,
        transition: {
            type: "tween",
            duration: 0.5,
            ease: [0.25, 0.1, 0.25, 1],
            opacity: { duration: 0.3 },
            rotateY: { duration: 0.5 },
            rotateZ: { duration: 0.5 }
        }
    }),
    center: {
        x: 0,
        y: 0,
        rotateY: 0,
        rotateZ: 0,
        scale: 1,
        opacity: 1,
        zIndex: 30,
        transition: {
            type: "tween",
            duration: 0.5,
            ease: [0.25, 0.1, 0.25, 1],
            opacity: { duration: 0.3 },
            rotateY: { duration: 0.5 },
            rotateZ: { duration: 0.5 }
        }
    },
    exit: (direction) => ({
        x: direction > 0 ? '15%' : '-15%',
        y: '5%',
        rotateY: direction > 0 ? 6 : -6,
        rotateZ: direction > 0 ? 6 : -6,
        scale: 0.95,
        opacity: 0.5,
        zIndex: 40,
        transition: {
            type: "tween",
            duration: 0.5,
            ease: [0.25, 0.1, 0.25, 1],
            opacity: { duration: 0.3 },
            rotateY: { duration: 0.5 },
            rotateZ: { duration: 0.5 }
        }
    })
};

const Proje = ({ id, item, last, phone, onNext, onPrev, projects, language }) => {
    const [[page, direction], setPage] = React.useState([0, 0]);
    const currentIndex = parseInt(id);
    
    const leftIndex = currentIndex === projects.length - 1 ? 0 : currentIndex + 1;
    const rightIndex = currentIndex === 0 ? projects.length - 1 : currentIndex - 1;

    const paginate = (newDirection) => {
        if (newDirection > 0) {
            onNext();
        } else {
            onPrev();
        }
        setPage([page + newDirection, newDirection]);
    };

    return (
        <div className="relative w-full min-h-[70vh] flex items-center justify-center py-10">
            <div className="project-stage w-[60vw] h-[60vh] relative">
                {!phone && (
                    <>
                        <button
                            onClick={() => paginate(-1)}
                            className="absolute left-[-80px] top-1/2 transform -translate-y-1/2 z-50 bg-blue-600 hover:bg-blue-700 text-white p-4 rounded-full transition-colors shadow-lg"
                        >
                            <FontAwesomeIcon icon={faChevronLeft} className="w-5 h-5" />
                        </button>
                        <button
                            onClick={() => paginate(1)}
                            className="absolute right-[-80px] top-1/2 transform -translate-y-1/2 z-50 bg-blue-600 hover:bg-blue-700 text-white p-4 rounded-full transition-colors shadow-lg"
                        >
                            <FontAwesomeIcon icon={faChevronRight} className="w-5 h-5" />
                        </button>
                    </>
                )}
                
                <AnimatePresence initial={false} custom={direction} mode="sync">
                    <motion.div
                        key={page}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        className="absolute w-full h-full"
                        style={{ 
                            transformStyle: 'preserve-3d',
                            perspective: '1000px',
                            zIndex: 30
                        }}
                    >
                        <div className="w-full h-full overflow-hidden bg-stone-900">
                            <ProjeItem item={item} isActive={true} language={language}/>
                        </div>
                    </motion.div>
                </AnimatePresence>

                <motion.div 
                    className="absolute w-full h-full rounded-[20px] overflow-hidden transform -rotate-6 scale-95"
                    style={{ 
                        transformStyle: 'preserve-3d',
                        zIndex: 10,
                        left: '-15%',
                        top: '5%',
                        backgroundColor: 'rgb(28, 25, 23)',
                        boxShadow: '0 0 0 1px rgba(87, 83, 78, 0.5)'
                    }}
                >
                    <ProjeItem item={projects[leftIndex]} isActive={false} language={language}/>
                </motion.div>

                <motion.div 
                    className="absolute w-full h-full rounded-[20px] overflow-hidden transform rotate-6 scale-95"
                    style={{ 
                        transformStyle: 'preserve-3d',
                        zIndex: 10,
                        right: '-15%',
                        top: '5%',
                        backgroundColor: 'rgb(28, 25, 23)',
                        boxShadow: '0 0 0 1px rgba(87, 83, 78, 0.5)'
                    }}
                >
                    <ProjeItem item={projects[rightIndex]} isActive={false} language={language}/>
                </motion.div>
            </div>
        </div>
    );
};

export default Proje;

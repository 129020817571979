import React, { useEffect, useRef } from 'react';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import profile from "../../img/profile.jpg";

const MobileHome = ({ showName, showButtons, language, setLanguage, informations }) => {
  const sectionRef = useRef(null);

  return (
    <div ref={sectionRef} className="min-h-screen bg-stone-900 px-4 py-16 relative flex flex-col justify-center items-center">
      {/* Language Selector */}
      <div className="absolute top-4 right-4 flex space-x-2 z-20">
        <button 
          className={`px-3 py-1 rounded-full flex items-center ${language === 'tr' ? 'bg-white text-stone-900' : 'bg-stone-700 text-white'}`}
          onClick={() => setLanguage('tr')}
        >
          TR
        </button>
        <button 
          className={`px-3 py-1 rounded-full flex items-center ${language === 'en' ? 'bg-white text-stone-900' : 'bg-stone-700 text-white'}`}
          onClick={() => setLanguage('en')}
        >
          EN
        </button>
      </div>

      {/* Profile Image */}
      <motion.div
        className="mb-8"
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        <img 
          src={profile} 
          alt="Profile" 
          className="w-48 h-48 rounded-full border-4 border-white shadow-xl"
        />
      </motion.div>

      {/* Text Content */}
      <motion.div
        className="text-center mb-8 w-full max-w-md"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <p className="text-2xl font-font text-stone-400 mb-2">
          {informations[language].hero.greeting}
        </p>
        <h1 className="text-4xl font-font text-white mb-4">
          {informations[language].hero.name}
        </h1>
        <h2 className="text-2xl font-font text-stone-300 mb-6">
          {informations[language].hero.role}
        </h2>
      </motion.div>

      {/* Social Links */}
      <motion.div
        className={`flex justify-center space-x-6 transition-all duration-500 ${
          showButtons ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <SocialButton href="https://www.instagram.com/ozlerrhalil/" icon={faInstagram} />
        <SocialButton href="https://github.com/Halilozler" icon={faGithub} />
        <SocialButton href="mailto: halil_ozler@hotmail.com" icon={faEnvelope} />
        <SocialButton href="https://www.linkedin.com/in/halilozler/" icon={faLinkedinIn} />
      </motion.div>

      {/* Scroll Indicator */}
      <motion.div
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        <motion.div
          animate={{ y: [0, 10, 0] }}
          transition={{ repeat: Infinity, duration: 1.5 }}
          className="w-6 h-10 border-2 border-white rounded-full flex justify-center"
        >
          <motion.div
            animate={{ y: [0, 12, 0] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
            className="w-1 h-3 bg-white rounded-full mt-2"
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

const SocialButton = ({ href, icon }) => (
  <a 
    href={href} 
    target="_blank" 
    rel="noreferrer" 
    className="bg-stone-700 hover:bg-stone-600 text-white p-3 rounded-full transition-all duration-300 hover:scale-110 hover:shadow-lg hover:shadow-stone-600/50"
  >
    <FontAwesomeIcon icon={icon} className="text-xl" />
  </a>
);

export default MobileHome; 
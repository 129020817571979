import React from 'react';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLanguage, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import profile from "../../img/profile.jpg";

const HeroSection = ({ showName, showButtons, language, setLanguage, informations }) => {
  const scrollToExperience = () => {
    const experienceSection = document.getElementById('experience');
    if (experienceSection) {
      experienceSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <motion.div 
      id="hero"
      className="h-screen relative flex items-center justify-center bg-gradient-to-b from-stone-900 to-stone-900"
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0, transition: {duration: 0.1}}}
    >
      {/* Language Selector */}
      <div className="absolute top-4 left-4 flex space-x-2 z-20">
        <button 
          className={`px-3 py-1 rounded-full flex items-center ${language === 'tr' ? 'bg-white text-stone-900' : 'bg-stone-700 text-white'}`}
          onClick={() => setLanguage('tr')}
        >
          <FontAwesomeIcon icon={faLanguage} className="mr-2" />
          TR
        </button>
        <button 
          className={`px-3 py-1 rounded-full flex items-center ${language === 'en' ? 'bg-white text-stone-900' : 'bg-stone-700 text-white'}`}
          onClick={() => setLanguage('en')}
        >
          <FontAwesomeIcon icon={faLanguage} className="mr-2" />
          EN
        </button>
      </div>

      {/* Main Content */}
      <div className={`max-w-7xl mx-auto px-4 w-full transition-all duration-500 ${showName ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
        <div className="flex flex-row-reverse items-center justify-between">
          {/* Profile Image */}
          <div className="w-1/2 flex justify-center">
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <img 
                src={profile} 
                alt="Profile" 
                className="w-96 h-96 rounded-full border-8 border-white shadow-2xl"
              />
            </motion.div>
          </div>

          {/* Text Content */}
          <div className="w-1/2">
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <p className="text-3xl font-font text-stone-400 mb-2">{informations[language].hero.greeting}</p>
              <h1 className="text-6xl font-font text-white mb-4">
                {informations[language].hero.name}
              </h1>
              <h2 className="text-4xl font-font text-stone-300 mb-6">{informations[language].hero.role}</h2>

              {/* Social Links */}
              <div className={`flex space-x-6 transition-all duration-500 ${showButtons ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                <SocialButton href="https://www.instagram.com/ozlerrhalil/" icon={faInstagram} text="Instagram" />
                <SocialButton href="https://github.com/Halilozler" icon={faGithub} text="Github" />
                <SocialButton href="mailto: halil_ozler@hotmail.com" icon={faEnvelope} text="Email" />
                <SocialButton href="https://www.linkedin.com/in/halilozler/" icon={faLinkedinIn} text="LinkedIn" />
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Scroll Indicator */}
      <motion.button 
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer"
        onClick={scrollToExperience}
        initial={{ y: 0 }}
        animate={{ y: [0, 10, 0] }}
        transition={{ repeat: Infinity, duration: 1.5 }}
      >
        <FontAwesomeIcon 
          icon={faArrowDown} 
          className="text-white text-3xl animate-bounce"
        />
      </motion.button>
    </motion.div>
  );
};

const SocialButton = ({ href, icon, text }) => (
  <a 
    href={href} 
    target="_blank" 
    rel="noreferrer" 
    className="bg-stone-700 hover:bg-stone-600 text-white p-4 rounded-full transition-all duration-300 hover:scale-110 hover:shadow-lg hover:shadow-stone-600/50 group"
  >
    <FontAwesomeIcon icon={icon} className="text-2xl group-hover:animate-bounce" />
  </a>
);

export default HeroSection; 
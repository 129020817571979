import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faAngular, faReact, faNode, faDocker, faPython } from "@fortawesome/free-brands-svg-icons";
import { faFile, faCode } from "@fortawesome/free-solid-svg-icons";
import sql from '../img/sql.png';
import net from '../img/net.png';
import kafka from '../img/kafka.png';
import defaultProjectImage from '../img/default-project.png';

// Global değişkenler
const DEFAULT_PROJECT_IMAGE = defaultProjectImage;

const iconMap = {
    'faGithub': faGithub,
    'faAngular': faAngular,
    'faReact': faReact,
    'faNode': faNode,
    'faDocker': faDocker,
    'faPython': faPython,
    'faFile': faFile,
    'sql': sql,
    'net': net,
    'kafka': kafka
};

const ProjeItem = ({ item, isActive = true, language = 'tr' }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(DEFAULT_PROJECT_IMAGE);
    const [imageError, setImageError] = useState(false);
    const hasMultipleImages = Array.isArray(item.img_Url) && item.img_Url.length > 1;

    // Debug için item'ı kontrol et
    useEffect(() => {
        // console.log("Item data:", item);
        // console.log("Image URLs:", item.img_Url);
    }, [item]);

    useEffect(() => {
        let isMounted = true;
        setImageError(false);

        const loadImage = async (url) => {
            if (!url || url === "none.png" || typeof url !== 'string') {
                if (isMounted) {
                    setCurrentImage(DEFAULT_PROJECT_IMAGE);
                    setImageError(true);
                }
                return;
            }

            try {
                const img = new Image();
                img.src = url;
                await new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                });
                
                if (isMounted) {
                    setCurrentImage(url);
                    setImageError(false);
                }
            } catch (error) {
                // console.error("Image load error for URL:", url);
                if (isMounted) {
                    setImageError(true);
                    setCurrentImage(DEFAULT_PROJECT_IMAGE);
                }
            }
        };

        const imageUrl = item.img_Url?.[currentImageIndex];
        // console.log("Trying to load image URL:", imageUrl);
        // console.log("Default project image:", DEFAULT_PROJECT_IMAGE);
        loadImage(imageUrl);

        return () => {
            isMounted = false;
        };
    }, [currentImageIndex, item.img_Url]);

    // Otomatik resim geçişi için - sadece aktif kart için
    useEffect(() => {
        if (isActive && hasMultipleImages) {
            const timer = setInterval(() => {
                setCurrentImageIndex((prev) => (prev + 1) % item.img_Url.length);
            }, 3000);

            return () => clearInterval(timer);
        }
    }, [isActive, hasMultipleImages, item.img_Url?.length]);

    // Kart değiştiğinde resim indexini sıfırla
    useEffect(() => {
        setCurrentImageIndex(0);
        setCurrentImage(DEFAULT_PROJECT_IMAGE);
    }, [item.title]);

    // Icon kontrolü
    const getIcon = (iconName) => {
        if (!iconName) return faCode;
        return iconMap[iconName] || faCode;
    };

    return (
        <motion.div 
            className="flex h-full bg-stone-900"
            initial={false}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
        >
            <div className="w-1/2 h-full p-8 flex flex-col">
                <div className="flex flex-wrap gap-4 mb-6">
                    {item.top_Link?.map((tech, index) => (
                        <div key={index} className="flex items-center gap-2 bg-stone-800 px-3 py-1.5 rounded-full">
                            {tech.img_name?.startsWith('fa') ? (
                                <FontAwesomeIcon 
                                    icon={getIcon(tech.img_name)} 
                                    className="w-5 h-5 text-white"
                                />
                            ) : (
                                <img 
                                    src={iconMap[tech.img_name] || ''}
                                    alt={tech.link || ''}
                                    className="w-5 h-5"
                                    onError={(e) => {
                                        e.target.style.display = 'none';
                                    }}
                                />
                            )}
                            <span className="text-white text-sm">{tech.link}</span>
                        </div>
                    ))}
                </div>

                <h3 className="text-3xl font-bold text-white mb-6">{item.title?.[language] || item.title}</h3>
                <p className="text-gray-300 text-lg flex-grow leading-relaxed">{item.text?.[language] || item.text}</p>

                <div className="flex gap-4 mt-6">
                    {item.bottom_Link?.map((link, index) => (
                        <a
                            key={index}
                            href={link.link}
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center gap-2 px-4 py-2 bg-stone-800 hover:bg-stone-700 rounded-lg transition-colors"
                        >
                            <FontAwesomeIcon 
                                icon={getIcon(link.img_name)} 
                                className="text-white w-5 h-5"
                            />
                            <span className="text-white font-medium">
                                {link.pdf ? (language === 'tr' ? "Dokümantasyon" : "Documentation") : "GitHub"}
                            </span>
                        </a>
                    ))}
                </div>
            </div>

            <div className="w-1/2 h-full relative overflow-hidden bg-stone-800">
                <motion.img 
                    key={currentImageIndex}
                    src={typeof currentImage === 'string' ? currentImage : DEFAULT_PROJECT_IMAGE}
                    alt={item.title || 'Proje Görseli'}
                    className="w-full h-full object-contain p-4"
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.4 }}
                    onError={() => {
                        // console.error("Image load error, using default image");
                        setImageError(true);
                        setCurrentImage(DEFAULT_PROJECT_IMAGE);
                    }}
                />
                
                {isActive && hasMultipleImages && !imageError && (
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2">
                        {item.img_Url?.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentImageIndex(index)}
                                className={`w-3 h-3 rounded-full transition-all ${
                                    index === currentImageIndex 
                                        ? 'bg-blue-500 scale-110' 
                                        : 'bg-gray-400 hover:bg-gray-300'
                                }`}
                                aria-label={`Go to image ${index + 1}`}
                            />
                        ))}
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default ProjeItem;
